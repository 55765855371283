body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #54a0ff;
 
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#comingSoonLogo{
  width: 300px;
  margin-right: auto;
  margin-left: auto;
  display: block;
  margin-top: 85px;
}

#comingSoonHeading{
  color: white;
  font-family: 'Rubik Mono One', sans-serif;
}
#testBtn {
  padding: 16px;
  width: 215px;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;
  font-size: 17px;
  border: none;
  background: #f39c12;
  color: white;
}

